// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Stars_Star__2KGjk{\n   color: #FCC401;\n}\n\n.Stars_StarsRendered__2h5_W{\n   font-size: 1.4rem;\n}", "",{"version":3,"sources":["webpack://src/UI/Stars/Stars.module.css"],"names":[],"mappings":"AAAA;GACG,cAAc;AACjB;;AAEA;GACG,iBAAiB;AACpB","sourcesContent":[".Star{\n   color: #FCC401;\n}\n\n.StarsRendered{\n   font-size: 1.4rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Star": "Stars_Star__2KGjk",
	"StarsRendered": "Stars_StarsRendered__2h5_W"
};
export default ___CSS_LOADER_EXPORT___;
