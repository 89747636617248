// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Section_Section__1BcTP {\n\tmargin: auto;\n\tpadding: 100px 0px;\n}\n\n.Section_Grey__X8XwM{\n   background: var(--grey-bg);\n}\n", "",{"version":3,"sources":["webpack://src/UI/HOC/Section/Section.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,kBAAkB;AACnB;;AAEA;GACG,0BAA0B;AAC7B","sourcesContent":[".Section {\n\tmargin: auto;\n\tpadding: 100px 0px;\n}\n\n.Grey{\n   background: var(--grey-bg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Section": "Section_Section__1BcTP",
	"Grey": "Section_Grey__X8XwM"
};
export default ___CSS_LOADER_EXPORT___;
