// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_Header__2C_Ub {\n\twidth: 100%;\n\theight: 70px;\n\tmax-height: 70px;\n\twidth: 100%;\n   background: #fff;\n\t/* box-shadow: 0px -2px 15px 0px rgb(102 102 102 / 20%); */\n}\n\n.Header_Content__10vg2 {\n\tdisplay: grid;\n\tgrid-template-columns: 50% 50%;\n\tmargin: auto;\n}\n\n.Header_Logo__18l3V{\n   max-width: 140px;\n   max-height: 30px;\n}\n\n.Header_HeaderMenu__1-KxT {\n\ttext-align: right;\n}\n\n.Header_HeaderMenuContent__12Ah0 {\n\tpadding: 8px 15px;\n\tdisplay: inline-block;\n}\n\n.Header_Book__10sYq {\n\tpadding: 8px 30px;\n}\n\n@media (min-width: 899px) {\n\t.Header_Header__2C_Ub {\n\t\tpadding: 15px 25px;\n   }\n   \n   .Header_Logo__18l3V {\n      max-width: 200px;\n      max-height: 70px;\n   }\n}\n", "",{"version":3,"sources":["webpack://src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,gBAAgB;CAChB,WAAW;GACT,gBAAgB;CAClB,0DAA0D;AAC3D;;AAEA;CACC,aAAa;CACb,8BAA8B;CAC9B,YAAY;AACb;;AAEA;GACG,gBAAgB;GAChB,gBAAgB;AACnB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,iBAAiB;CACjB,qBAAqB;AACtB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC;EACC,kBAAkB;GACjB;;GAEA;MACG,gBAAgB;MAChB,gBAAgB;GACnB;AACH","sourcesContent":[".Header {\n\twidth: 100%;\n\theight: 70px;\n\tmax-height: 70px;\n\twidth: 100%;\n   background: #fff;\n\t/* box-shadow: 0px -2px 15px 0px rgb(102 102 102 / 20%); */\n}\n\n.Content {\n\tdisplay: grid;\n\tgrid-template-columns: 50% 50%;\n\tmargin: auto;\n}\n\n.Logo{\n   max-width: 140px;\n   max-height: 30px;\n}\n\n.HeaderMenu {\n\ttext-align: right;\n}\n\n.HeaderMenuContent {\n\tpadding: 8px 15px;\n\tdisplay: inline-block;\n}\n\n.Book {\n\tpadding: 8px 30px;\n}\n\n@media (min-width: 899px) {\n\t.Header {\n\t\tpadding: 15px 25px;\n   }\n   \n   .Logo {\n      max-width: 200px;\n      max-height: 70px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": "Header_Header__2C_Ub",
	"Content": "Header_Content__10vg2",
	"Logo": "Header_Logo__18l3V",
	"HeaderMenu": "Header_HeaderMenu__1-KxT",
	"HeaderMenuContent": "Header_HeaderMenuContent__12Ah0",
	"Book": "Header_Book__10sYq"
};
export default ___CSS_LOADER_EXPORT___;
