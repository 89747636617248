import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import css from './Stars.module.css';

const Stars = (props) => {
	let numberOfStars = props.input;

	const starsRendered = [];

	const fullStar = <FontAwesomeIcon icon={faStar} className={css.Star} />;
	const halfStar = <FontAwesomeIcon icon={faStarHalf} className={css.Star} />;

	while (numberOfStars > 0) {
		numberOfStars / 1 >= 1
			? starsRendered.push(<span key={numberOfStars}>{fullStar}</span>)
			: numberOfStars / 1 > 0 &&
			  starsRendered.push(<span key={numberOfStars}>{halfStar}</span>);
		numberOfStars--;
	}

	return (
		<>
			{props.input} <span className={css.StarsRendered}>{starsRendered}</span>
		</>
	);
};
export default Stars;
