// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InnerGridContainer_InnerGridContainer__B9y7A {\n\tdisplay: flex;\n\tmargin: 80px 0px;\n}\n\n.InnerGridContainer_InnerGridContainer__B9y7A img {\n\tmax-width: 100%;\n}\n\n.InnerGridContainer_InnerGridContainer__B9y7A div {\n\tmax-width: 100%;\n}\n\n.InnerGridContainer_AlignTop__3dT2j {\n\talign-items: top;\n}\n\n.InnerGridContainer_AlignMiddle__3Zwwh {\n\talign-items: center;\n}\n\n@media (min-width: 798px) {\n\t.InnerGridContainer_InnerGridContainer__B9y7A {\n\t\tmargin: 40px 0px;\n\t}\n}\n", "",{"version":3,"sources":["webpack://src/UI/HOC/InnerGridContainer/InnerGridContainer.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,gBAAgB;AACjB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC;EACC,gBAAgB;CACjB;AACD","sourcesContent":[".InnerGridContainer {\n\tdisplay: flex;\n\tmargin: 80px 0px;\n}\n\n.InnerGridContainer img {\n\tmax-width: 100%;\n}\n\n.InnerGridContainer div {\n\tmax-width: 100%;\n}\n\n.AlignTop {\n\talign-items: top;\n}\n\n.AlignMiddle {\n\talign-items: center;\n}\n\n@media (min-width: 798px) {\n\t.InnerGridContainer {\n\t\tmargin: 40px 0px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InnerGridContainer": "InnerGridContainer_InnerGridContainer__B9y7A",
	"AlignTop": "InnerGridContainer_AlignTop__3dT2j",
	"AlignMiddle": "InnerGridContainer_AlignMiddle__3Zwwh"
};
export default ___CSS_LOADER_EXPORT___;
