const data = {
	hostname: 'www.ringwoodservicecentre.com.au',
	id: 'ringwoodservicecentre',
	bookingLink: 'https://book.repairy.au/ringwoodservicecentre',
	name: 'Ringwood Service Centre',
	logo: '/images/RingwoodServiceCentre/logo.jpg',
	heroImage: '/images/RingwoodServiceCentre/hero.jpg',
	theme: '#f01426',
	intro:
		'We’re a well-known, long-established business in Ringwood, providing services for all surrounding areas. We provide a truly comprehensive range of services for all types of vehicles.',
	services: [
		{
			id: 1,
			type: 'Logbook Services',
			content:
				'All our log book servicing is performed as per the factory schedules to ensure your warranty will be maintained regardless of the vehicle.',
		},
		{
			id: 2,
			type: 'Battery replacement',
			content:
				'We offer full battery inspection and replacement as well as vehicle safety inspections.',
		},
		{
			id: 3,
			type: 'Air Conditioning',
			certification: 'AU55946',
			certificationImage: '/images/RingwoodServiceCentre/arctick.png',
			content:
				'Our technicians are equipped with the knowledge and hands-on experience in repairing, replacing and servicing the components of the air conditioning systems on all vehicles.',
		},
		{
			id: 4,
			type: 'Tyres & Alignment',
			content:
				"Today's modern suspensions require precise wheel alignments that can only be achieved through a modern alignment system.",
		},
	],
	otherServices: [
		'Tyres & Balancing',
		'Air Conditioning',
		'Auto Electrics',
		'Suspension',
		'Battery replacement',
		'General Mechanical Repairs',
		'Brake & Clutch Services',
		'Engine Diagnostic',
		'Roadworthy Services',
	],
	reasons: [
		{
			id: 1,
			image: '/images/RingwoodServiceCentre/years.jpg',
			title: 'Years of experience',
			desc:
				'Our team consist of skilled mechanics that take pride in delivering premium and comprehensive mechanical assistance using the latest technology.',
		},
		{
			id: 2,
			image: '/images/RingwoodServiceCentre/trust.jpg',
			title: 'Service you can trust',
			desc:
				'We pride ourselves in delivering the highest quality of customer service and products to our valued customers.',
		},
		{
			id: 3,
			image: '/images/RingwoodServiceCentre/above.jpg',
			title: 'Above and beyond',
			desc:
				"If you're not happy with the service we provide, please let us know and we will do our best to achieve 100% customer satisfaction.",
		},
	],
	about: {
		image: '/images/RingwoodServiceCentre/about.jpg',
		content: [
			<div>About Ringwood Service Centre</div>,
			<div>How we started this journey</div>,
			<div>
				Ringwood Service Centre is owned and managed by Wayne and Ricky who
				both started off as a mobile mechanic during their Uni days.
				Throughout the years, they have earned a reputation for being
				genuine who charge fair prices while providing phenomenal services
				to their customers in the local community.
				<br />
				<br />
				Wayne got his start in automotive from a very young age, growing up
				and seeing his dad run a workshop since the 80s. Wayne holds a
				Bachelor in Civil Engineering but his love in cars brought him back
				in pursuing the automotive industry. Ricky who has a background in
				Electronic Engineering and Programming. With a change in scenery, he
				completed his automotive apprenticeship with an outstanding
				performance award and now set his sights on high performance car
				modification.
				<br />
				<br />
				With both their passions and diverse experiences in cars, which make
				Ringwood Service Centre stand out from the others, as they always
				say “ Though customer need is on their priority, to ensure the
				safety of the car is what they are here for!”
				<br />
				<br />
				<span style={{ fontSize: '1.4rem', fontWeight: '500' }}>
					Wayne and Ricky
				</span>
				<br />
				<span style={{ fontSize: '0.9rem' }}>
					Owners - Ringwood Service Centre.
				</span>
			</div>,
		],
	},
	reviews: [
		{
			link:
				'https://www.google.com/search?q=ringwood+service+centre&rlz=1C5CHFA_enAU776AU776&oq=ringwo&aqs=chrome.0.69i59j46i39i175i199j69i57j69i59j69i60l2j69i61j69i60.2215j0j7&sourceid=chrome&ie=UTF-8#lrd=0x6ad6395d8b4e8999:0x91f5ca645979b3ad,1,,,',
			score: '5',
			totalReview: '18',
			type: 'Google',
		},
		{
			link: 'https://www.facebook.com/RingwoodServiceCentre/reviews/',
			score: '5',
			totalReview: '6',
			type: 'Facebook',
		},
	],
	contacts: {
		googleMap:
			'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.529234428439!2d145.21742731611957!3d-37.82449437975041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6395d8b4e8999%3A0x91f5ca645979b3ad!2sRingwood%20Service%20Centre%20-%20Ringwood%20Mechanic!5e0!3m2!1sen!2sau!4v1608092648101!5m2!1sen!2sau',
		address: ['3/7 Elliot Place', 'Ringwood VIC 3134'],
		phone: '03 9870 3005',
		email: 'enquiry@ringwoodservicecentre.com.au',
		opening: ['Monday - Friday: 8am - 5pm'],
	},
	isExtraLogos: false,
	extraLogos: [],
};

export default data;
