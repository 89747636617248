import { action } from 'easy-peasy';

const FaqModels = {
	currentFaqId: 0,
	setCurrentFaqId: action((state, payload) => {
		state.currentFaqId = payload;
	}),
};

export default FaqModels;
