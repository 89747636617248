const data = {
	hostname: 'www.tyrepluscaulfield.com.au',
	id: 'tyrepluscaulfield',
	bookingLink: 'https://book.repairy.com.au/tyrepluscaulfield',
	name: 'Tyre Plus Caulfield',
	logo: '/images/TyreplusCaulfield/logo.png',
	heroImage: '/images/TyreplusCaulfield/hero.jpg',
	theme: '#28a745',
	intro:
		'At our workshop, we pride ourselves on the quality of our repairs, the depth of our industry experience, and the extent of our customer service',
	services: [
		{
			id: 1,
			type: 'Tyre Specialist',
			content:
				'We offer a great range of tyres, including Michelin and BF Goodrich and have the experience and equipment to make sure you get the right job done the first time.',
		},
		{
			id: 2,
			type: 'Basic services',
			content:
				'Our team is fully qualified to assist you with any minor repairs or regular maintenance.',
		},
		{
			id: 3,
			type: 'Battery replacement',
			content:
				'We offer full battery inspection and replacement as well as vehicle safety inspections.',
		},
		{
			id: 4,
			type: 'Wheel Alignment',
			content:
				"Today's modern suspensions require precise wheel alignments that can only be achieved through a modern alignment system.",
		},
	],
	otherServices: [
		'Tyres & Balancing',
		'Air Conditioning',
		'Auto Electrics',
		'Suspension',
		'Key Drop-off box',
		'Battery replacement',
		'General Mechanical Repairs',
		'Puncture Repair',
	],
	reasons: [
		{
			id: 1,
			image: '/images/TyreplusCaulfield/experience.jpg',
			title: 'Years of experience',
			desc:
				'Our team consist of skilled mechanics that take pride in delivering premium and comprehensive mechanical assistance using the latest technology.',
		},
		{
			id: 2,
			image: '/images/TyreplusCaulfield/trust.jpg',
			title: 'Service you can trust',
			desc:
				'We pride ourselves in delivering the highest quality of customer service and products to our valued customers.',
		},
		{
			id: 3,
			image: '/images/TyreplusCaulfield/above.jpg',
			title: 'Above and beyond',
			desc:
				"If you're not happy with the service we provide, please let us know and we will do our best to achieve 100% customer satisfaction.",
		},
	],
	about: {
		image: '/images/TyreplusCaulfield/owner.jpg',
		content: [
			<div key="sub-title">About Tyreplus Caulfield</div>,
			<div key="title">How we started our journey</div>,
			<div key="content">
				The team here at TYREPLUS Caulfield is lead by one of Melbourne's
				most experienced TYREPLUS franchise owner, Gordon Pereira. Gordon
				has been in the tyre industry since 1985.
				<br />
				<br />
				Gordon is supported by a young and dynamic team which is lead by his
				son, Andrew Pereira whose knowledge is extraordinary beyond his
				years.
				<br />
				<br />
				The father and son team combination of long term experience and
				youthful energy ensures a high level of service and expertise that
				will exceed expectations.
				<br />
				<br />
				<span style={{ fontSize: '1.4rem', fontWeight: '500' }}>
					Gordon Pereira
				</span>
				<br />
				<span style={{ fontSize: '0.9rem' }}>
					Franchise Owner - Tyreplus Caulfield.
				</span>
			</div>,
		],
	},
	reviews: [
		{
			link:
				'https://www.google.com/search?q=tyreplus+caulfield&rlz=1C5CHFA_enAU776AU776&oq=tyreplus+caul&aqs=chrome.0.69i59j46i39i175i199j69i57j69i60l3.3948j0j7&sourceid=chrome&ie=UTF-8#lrd=0x6ad66909f7f8ab9b:0x828ee6bfb5872bb8,1,,,',
			score: '4.8',
			totalReview: '21',
			type: 'Google',
		},
		{
			link:
				'https://www.facebook.com/Tyreplus-Caulfield-112434077052782/reviews',
			score: '5',
			totalReview: '2',
			type: 'Facebook',
		},
	],
	contacts: {
		googleMap:
			'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12598.881315989782!2d145.0280263!3d-37.8668338!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x828ee6bfb5872bb8!2sTyreplus%20Caulfield!5e0!3m2!1sen!2sau!4v1608463616809!5m2!1sen!2sau',
		address: ['536 Dandenong Rd,', 'Caulfield North, VIC 3161.'],
		phone: '03 8657 0600 / 0418109199',
		email: 'tyrepluscaulfield@gmail.com',
		opening: ['Monday - Friday: 8am - 5pm', 'Saturday: 8am - 12.00pm'],
	},
	isExtraLogos: true,
	extraLogos: [
		'/images/Others/michelin-logo.png',
		'/images/Others/BFGoodrich-logo-small-med.png',
		'/images/Others/continental-logo.png',
		'/images/Others/pirelli-logo.png',
		'/images/Others/bridgestone-logo.png',
		'/images/Others/zeta-logo.png',
	],
	isExtraLogosFeatured: true,
};

export default data;
