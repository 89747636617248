import React from 'react';
import css from './Grid.module.css';

const Grid = (props) => {
	const { column } = props;
	let cssColumn = '';
	switch (column) {
		case 1:
			cssColumn = css.Grid_1;
			break;
		case 2:
			cssColumn = css.Grid_2;
			break;
		case 3:
			cssColumn = css.Grid_3;
			break;
		case 7:
			cssColumn = css.Grid_7;
			break;
		default:
			cssColumn = css.Grid_1;
			break;
	}

	const gridGapCss = !props.isGap ? '' : css.GridGap;

	return (
		<div
			style={props.style}
			className={[css.Grid, cssColumn, props.className, gridGapCss].join(
				' '
			)}
		>
			{props.children}
		</div>
	);
};
export default Grid;
