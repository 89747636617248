import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Repairy from './containers/Repairy';
import { StoreProvider, createStore } from 'easy-peasy';
import model from './models/App.models';

function App() {
   const store = createStore(model);
	return (
		<StoreProvider store={store}>
			<BrowserRouter>
				<Repairy />
			</BrowserRouter>
		</StoreProvider>
	);
}

export default App;
