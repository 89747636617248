// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReasonCard_Title__3ghF4 {\n\tmargin-top: 20px;\n\tfont-family: var(--font-quicksand);\n\tfont-size: 1.4rem;\n}\n\n.ReasonCard_Description__O6miy {\n\tfont-size: 1rem;\n\tfont-family: var(--roboto);\n\tmargin-top: 15px;\n\tline-height: 1.7rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/Homepage/Reason.page/ReasonCard/ReasonCard.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,kCAAkC;CAClC,iBAAiB;AAClB;;AAEA;CACC,eAAe;CACf,0BAA0B;CAC1B,gBAAgB;CAChB,mBAAmB;AACpB","sourcesContent":[".Title {\n\tmargin-top: 20px;\n\tfont-family: var(--font-quicksand);\n\tfont-size: 1.4rem;\n}\n\n.Description {\n\tfont-size: 1rem;\n\tfont-family: var(--roboto);\n\tmargin-top: 15px;\n\tline-height: 1.7rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Title": "ReasonCard_Title__3ghF4",
	"Description": "ReasonCard_Description__O6miy"
};
export default ___CSS_LOADER_EXPORT___;
