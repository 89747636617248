import React from 'react';
import css from './ReasonCard.module.css';

const ReasonCard = (props) => (
	<div className={css.ReasonCard}>
		<img src={props.img} alt='images' />
      <div className={css.Title}>{props.children[0]}</div>
      <div className={css.Description}>{props.children[1]}</div>
	</div>
);
export default ReasonCard;
