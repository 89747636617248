import React from 'react';
import css from './Button.module.css';

const Button = (props) => {
	const cssColorStyle =
		props.color === 'main'
			? css.MainColor
			: props.color === 'danger'
			? css.DangerColor
			: props.color === 'inverted'
			? css.Inverted
			: css.DefaultColor;

	return (
		<button
			onClick={props.onClick}
			className={[
				css.Button,
				props.className,
				cssColorStyle,
				props.type === 'menu' && css.Menu,
			].join(' ')}
		>
			{props.children}
		</button>
	);
};
export default Button;
