import React, { useState } from 'react';
import css from './Services.module.css';
import Content from '../../../UI/HOC/Content/Content';
import Section from '../../../UI/HOC/Section/Section';
import SectionTitle from '../../../UI/HOC/Section/SectionTitle/SectionTitle';
import ServicesIcons from './Services.icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronCircleDown,
	faChevronCircleRight,
} from '@fortawesome/free-solid-svg-icons';

const ServicesPage = (props) => {
	const [showFullOfferings, setShowFullOfferings] = useState(false);

	const fullOfferingsToggle = () => {
		setShowFullOfferings(!showFullOfferings);
	};

	const { services } = props;

	return (
		<Section>
			<Content>
				<SectionTitle>
					<>Some of our services</>
					<>
						We are covering a huge range of services to keep your car top
						notch, this is just some of them.
					</>
				</SectionTitle>
				<div className={css.ServicesContent}>
					<div className={css.Flex}>
						{services.map((service) => (
							<ServicesIcons
								key={service.id}
								type={service.type}
								description={service.content}
								certification={service.certification}
								certificationImage={service.certificationImage}
							/>
						))}
					</div>
				</div>
				<div
					onClick={fullOfferingsToggle}
					className={css.FullOfferingsButton}
				>
					<FontAwesomeIcon
						style={{ marginRight: '8px' }}
						icon={
							showFullOfferings
								? faChevronCircleDown
								: faChevronCircleRight
						}
					/>
					{showFullOfferings
						? 'Hide our full service offerings'
						: 'See our full service offerings here'}
				</div>
				<div
					className={[
						css.FullOfferingsContainer,
						showFullOfferings && css.FullOfferingsContainerOn,
					].join(' ')}
				>
					{props.otherServices.map((otherService) => (
						<ServicesIcons
							isSmall
							type={otherService}
							key={otherService}
						/>
					))}
				</div>
			</Content>
		</Section>
	);
};
export default ServicesPage;
