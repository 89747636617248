// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.About_Flex__DLEqq {\n\tdisplay: inline-flex;\n\tflex-wrap: wrap;\n\tmargin: -60px 0 0 -60px;\n\twidth: calc(100% + 60px);\n}\n\n.About_Flex__DLEqq > * {\n\tmargin: 60px 0 0 60px;\n}\n\n.About_Content__ATjLO{\n   width: calc(60% - 60px);\n}\n\n.About_Photo__3d__m{\n   width: calc(40% - 60px);\n}\n\n@media(max-width:799px){\n   .About_Content__ATjLO{\n      width: 100%;\n   }\n   \n   .About_Photo__3d__m{\n      width: 100%;\n   }\n}\n", "",{"version":3,"sources":["webpack://src/components/Homepage/About.page/About.module.css"],"names":[],"mappings":";AACA;CACC,oBAAoB;CACpB,eAAe;CACf,uBAAuB;CACvB,wBAAwB;AACzB;;AAEA;CACC,qBAAqB;AACtB;;AAEA;GACG,uBAAuB;AAC1B;;AAEA;GACG,uBAAuB;AAC1B;;AAEA;GACG;MACG,WAAW;GACd;;GAEA;MACG,WAAW;GACd;AACH","sourcesContent":["\n.Flex {\n\tdisplay: inline-flex;\n\tflex-wrap: wrap;\n\tmargin: -60px 0 0 -60px;\n\twidth: calc(100% + 60px);\n}\n\n.Flex > * {\n\tmargin: 60px 0 0 60px;\n}\n\n.Content{\n   width: calc(60% - 60px);\n}\n\n.Photo{\n   width: calc(40% - 60px);\n}\n\n@media(max-width:799px){\n   .Content{\n      width: 100%;\n   }\n   \n   .Photo{\n      width: 100%;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Flex": "About_Flex__DLEqq",
	"Content": "About_Content__ATjLO",
	"Photo": "About_Photo__3d__m"
};
export default ___CSS_LOADER_EXPORT___;
