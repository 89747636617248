// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Grid_Grid__DvtLf {\n\tdisplay: grid;\n}\n\n.Grid_Grid_2__2bE5V {\n\tgrid-template-columns: repeat(2, 1fr);\n}\n\n.Grid_Grid_3__27ZNe {\n\tgrid-template-columns: repeat(3, 1fr);\n}\n\n.Grid_Grid_7__QqFHd {\n\tgrid-template-columns: repeat(7, 1fr);\n}\n\n.Grid_GridGap__35kvf {\n\tgrid-gap: 80px;\n}\n\n.Grid_Grid__DvtLf > div:first-child {\n\tmargin-top: 0px;\n}\n.Grid_Grid__DvtLf > div:last-child {\n\tmargin-bottom: 0px;\n}\n\n@media (max-width: 798px) {\n\t.Grid_Grid__DvtLf {\n\t\tdisplay: block;\n\t}\n}\n", "",{"version":3,"sources":["webpack://src/UI/HOC/Grid/Grid.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;;AAEA;CACC,qCAAqC;AACtC;;AAEA;CACC,qCAAqC;AACtC;;AAEA;CACC,qCAAqC;AACtC;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,eAAe;AAChB;AACA;CACC,kBAAkB;AACnB;;AAEA;CACC;EACC,cAAc;CACf;AACD","sourcesContent":[".Grid {\n\tdisplay: grid;\n}\n\n.Grid_2 {\n\tgrid-template-columns: repeat(2, 1fr);\n}\n\n.Grid_3 {\n\tgrid-template-columns: repeat(3, 1fr);\n}\n\n.Grid_7 {\n\tgrid-template-columns: repeat(7, 1fr);\n}\n\n.GridGap {\n\tgrid-gap: 80px;\n}\n\n.Grid > div:first-child {\n\tmargin-top: 0px;\n}\n.Grid > div:last-child {\n\tmargin-bottom: 0px;\n}\n\n@media (max-width: 798px) {\n\t.Grid {\n\t\tdisplay: block;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Grid": "Grid_Grid__DvtLf",
	"Grid_2": "Grid_Grid_2__2bE5V",
	"Grid_3": "Grid_Grid_3__27ZNe",
	"Grid_7": "Grid_Grid_7__QqFHd",
	"GridGap": "Grid_GridGap__35kvf"
};
export default ___CSS_LOADER_EXPORT___;
