// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Content_Content__3o1RG {\n   padding:0px 15px;\n   width: 100%;\n   box-sizing: border-box;\n\n}\n\n@media (min-width: 899px) {\n\t.Content_Content__3o1RG {\n      padding: 0px;\n\t\tmargin: auto;\n\t\tmax-width: 1050px;\n      min-width: 800px;\n\t}\n}\n", "",{"version":3,"sources":["webpack://src/UI/HOC/Content/Content.module.css"],"names":[],"mappings":"AAAA;GACG,gBAAgB;GAChB,WAAW;GACX,sBAAsB;;AAEzB;;AAEA;CACC;MACK,YAAY;EAChB,YAAY;EACZ,iBAAiB;MACb,gBAAgB;CACrB;AACD","sourcesContent":[".Content {\n   padding:0px 15px;\n   width: 100%;\n   box-sizing: border-box;\n\n}\n\n@media (min-width: 899px) {\n\t.Content {\n      padding: 0px;\n\t\tmargin: auto;\n\t\tmax-width: 1050px;\n      min-width: 800px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Content": "Content_Content__3o1RG"
};
export default ___CSS_LOADER_EXPORT___;
