import React from 'react';
import css from './Contact.module.css';
import Content from '../../../UI/HOC/Content/Content';
import Section from '../../../UI/HOC/Section/Section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEnvelope,
	faMapMarkedAlt,
	faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';

const ContactPage = (props) => {
	const { contacts, extraLogos, workshopName } = props;

	return (
		<Section className={css.ContactPageSection}>
			<Content>
				<div className={css.ContactPage}>
					<div className={css.Location}>
						<iframe title="location" src={contacts.googleMap} />
					</div>

					<div className={css.Contact}>
						<div className={css.HeaderList}>{workshopName}</div>
						<div className={css.ItemList}>
							<div className={css.Icons}>
								<FontAwesomeIcon icon={faMapMarkedAlt} />{' '}
							</div>
							{contacts.address[0]} <br />
							{contacts.address[1]} <br />
						</div>

						<div className={css.ItemList}>
							<div className={css.Icons}>
								<FontAwesomeIcon icon={faPhoneAlt} />
							</div>
							{contacts.phone}
						</div>
						<div className={css.ItemList}>
							<div className={css.Icons}>
								<FontAwesomeIcon icon={faEnvelope} />
							</div>
							{contacts.email}
						</div>
						<div className={css.ItemList}>
							<div className={css.Icons}>
								<FontAwesomeIcon icon={faClock} />
							</div>
							{contacts.opening[0]}
							<br />
							{contacts.opening[1]}
						</div>
					</div>
					<div className={css.LogoContainer}>
						{extraLogos.map((logo) => {
							return (
								<img
									key={logo}
									src={logo}
									alt="extra"
								/>
							);
						})}
					</div>
				</div>
			</Content>
		</Section>
	);
};
export default ContactPage;
