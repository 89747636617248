// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExtrasLogo_ExtrasLogoContainer__AQlSC {\n\tpadding-top: 30px;\n   padding-bottom: 40px;\n   text-align: center;\n}\n.ExtrasLogo_ExtraLogo__2eF-M {\n\tmax-width: 300px;\n\tmax-height: 80px;\n   margin-right: 50px;\n   margin-bottom: 40px;\n}\n\n.ExtrasLogo_ExtraLogo__2eF-M:last-of-type {\n\tmargin-right: 0px;\n}\n", "",{"version":3,"sources":["webpack://src/components/Homepage/ExtrasLogo.page/ExtrasLogo.module.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;GACf,oBAAoB;GACpB,kBAAkB;AACrB;AACA;CACC,gBAAgB;CAChB,gBAAgB;GACd,kBAAkB;GAClB,mBAAmB;AACtB;;AAEA;CACC,iBAAiB;AAClB","sourcesContent":[".ExtrasLogoContainer {\n\tpadding-top: 30px;\n   padding-bottom: 40px;\n   text-align: center;\n}\n.ExtraLogo {\n\tmax-width: 300px;\n\tmax-height: 80px;\n   margin-right: 50px;\n   margin-bottom: 40px;\n}\n\n.ExtraLogo:last-of-type {\n\tmargin-right: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExtrasLogoContainer": "ExtrasLogo_ExtrasLogoContainer__AQlSC",
	"ExtraLogo": "ExtrasLogo_ExtraLogo__2eF-M"
};
export default ___CSS_LOADER_EXPORT___;
