import React from 'react';
import css from './Hero.module.css';
import Grid from '../../../UI/HOC/Grid/Grid';
import ParagraphSet from '../../../UI/HOC/ParagraphSet/ParagraphSet';
import Button from '../../../UI/Button/Button';
import InnerGridContainer from '../../../UI/HOC/InnerGridContainer/InnerGridContainer';
import Content from '../../../UI/HOC/Content/Content';
import Section from '../../../UI/HOC/Section/Section';

const Hero = (props) => {
	return (
		<Section className={css.HeroSection}>
			<Content className={css.Hero}>
				<div className={css.Content}>
					<Grid column={2} className={css.Grid} isGap={true}>
						<InnerGridContainer
							valign="middle"
							className={css.RightContainer}
						>
							<div>
								<ParagraphSet>
									<span className={css.HeroTitleSnippet}>
										Welcome to
									</span>
									<span className={css.HeroTitle}>{props.name}</span>
									<span className={css.HeroText}>{props.intro}</span>
								</ParagraphSet>
								<div className={css.ButtonGroup}>
									<Button
										onClick={() => {
											window.location.href = props.bookingLink;
										}}
										color="main"
									>
										Book a service
									</Button>
									{/* <Button onClick={scrollToFeatures}>Our services</Button> */}
								</div>
							</div>
						</InnerGridContainer>
						<div className={css.LeftContainer}>
							<img
								className={css.HeroImage}
								alt="Repairy Happy Workshop Owner"
								src={props.heroImage}
							/>
						</div>
					</Grid>
				</div>
			</Content>
		</Section>
	);
};
export default Hero;
