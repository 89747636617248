import React from 'react';
import css from './Section.module.css';

const Section = (props) => (
	<div
		{...props}
		ref={props.forwardref}
		className={[css.Section, props.className, props.type === 'grey' && css.Grey].join(' ')}
	>
		{props.children}
	</div>
);
export default Section;
