import React from 'react';
import Section from '../../../UI/HOC/Section/Section';
import css from './Reason.module.css';
import Content from '../../../UI/HOC/Content/Content';
import ReasonCard from './ReasonCard/Reason.card';
import SectionTitle from '../../../UI/HOC/Section/SectionTitle/SectionTitle';

const Reason = (props) => {
	const { reasons } = props;

	return (
		<Section type="grey">
			<Content>
				<SectionTitle>
					<>Few reasons to choose us</>
					<>
						Trust is a difficult thing to build, but we can assure you why
						you should trust us with your car.
					</>
				</SectionTitle>
				<div className={css.Flex}>
					<ReasonCard img={reasons[0].image}>
						<>{reasons[0].title}</>
						<>{reasons[0].desc}</>
					</ReasonCard>
					<ReasonCard img={reasons[1].image}>
						<>{reasons[1].title}</>
						<>{reasons[1].desc}</>
					</ReasonCard>
               <ReasonCard img={reasons[2].image}>
						<>{reasons[2].title}</>
						<>{reasons[2].desc}</>
					</ReasonCard>
				</div>
			</Content>
		</Section>
	);
};
export default Reason;
