// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Repairy_Repairy__3vI3U{\n   width: 100%;\n   height: 100%;\n}", "",{"version":3,"sources":["webpack://src/containers/Repairy.module.css"],"names":[],"mappings":"AAAA;GACG,WAAW;GACX,YAAY;AACf","sourcesContent":[".Repairy{\n   width: 100%;\n   height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Repairy": "Repairy_Repairy__3vI3U"
};
export default ___CSS_LOADER_EXPORT___;
