import React from 'react';
import css from './Header.module.css';
import Button from '../../UI/Button/Button';
import Content from '../../UI/HOC/Content/Content';

const Header = (props) => {
	const { logo } = props;
	return (
		<div className={css.Header}>
			<Content className={css.Content}>
				<div>
					<img src={logo} alt="workshop logo" className={css.Logo} />
				</div>
				<div className={css.HeaderMenu}>
					{/* <div className={css.HeaderMenuContent}>03 9292 9292</div> */}
					<Button
						className={css.Book}
						type="menu"
						onClick={() => {
							window.location.href = props.bookingLink;
						}}
						color="main"
					>
						Book a service
					</Button>
				</div>
			</Content>
		</div>
	);
};
export default Header;
