import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Homepage from '../components/Homepage/Home.page';
import css from './Repairy.module.css';

const Repairy = (props) => {
	return (
		<div className={css.Repairy}>
			<Switch>
				<Route path="/:id" exact>
					<Homepage />
				</Route>
				<Route path="/" exact>
					<Homepage />
				</Route>
				<Route>
					<div style={{ marginTop: '100px' }}>
						I cannot find what you are looking for!
					</div>
				</Route>
			</Switch>
			{/* <Footer /> */}
		</div>
	);
};
export default Repairy;
