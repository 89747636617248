// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SectionTitle_SectionTitle__334P_ {\n\twidth: 100%;\n   text-align: left;\n   font-family: var(--font-quicksand);\n   font-size: 2.2rem;\n   margin-bottom: 50px;\n}\n\n.SectionTitle_Line__9eoSt {\n\tmargin-bottom: 15px;\n\twidth: 70px;\n\theight: 3px;\n   background: var(--main-color);\n   max-width: 300px;\n}\n\n.SectionTitle_SectionTitleDesc__2udjm{\n   margin-top: 12px;\n   max-width: 500px;\n   font-size: 1rem;\n   line-height: 1.75;\n   font-family: var(--font-roboto);\n}\n", "",{"version":3,"sources":["webpack://src/UI/HOC/Section/SectionTitle/SectionTitle.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;GACT,gBAAgB;GAChB,kCAAkC;GAClC,iBAAiB;GACjB,mBAAmB;AACtB;;AAEA;CACC,mBAAmB;CACnB,WAAW;CACX,WAAW;GACT,6BAA6B;GAC7B,gBAAgB;AACnB;;AAEA;GACG,gBAAgB;GAChB,gBAAgB;GAChB,eAAe;GACf,iBAAiB;GACjB,+BAA+B;AAClC","sourcesContent":[".SectionTitle {\n\twidth: 100%;\n   text-align: left;\n   font-family: var(--font-quicksand);\n   font-size: 2.2rem;\n   margin-bottom: 50px;\n}\n\n.Line {\n\tmargin-bottom: 15px;\n\twidth: 70px;\n\theight: 3px;\n   background: var(--main-color);\n   max-width: 300px;\n}\n\n.SectionTitleDesc{\n   margin-top: 12px;\n   max-width: 500px;\n   font-size: 1rem;\n   line-height: 1.75;\n   font-family: var(--font-roboto);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SectionTitle": "SectionTitle_SectionTitle__334P_",
	"Line": "SectionTitle_Line__9eoSt",
	"SectionTitleDesc": "SectionTitle_SectionTitleDesc__2udjm"
};
export default ___CSS_LOADER_EXPORT___;
