import { action } from 'easy-peasy';

const UIModels = {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload; // boolean
	}),
};

export default UIModels;
