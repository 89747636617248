import React from 'react';
import Section from '../../../UI/HOC/Section/Section';
import css from './About.module.css';
import Content from '../../../UI/HOC/Content/Content';
import InnerGridContainer from '../../../UI/HOC/InnerGridContainer/InnerGridContainer';
import ParagraphSet from '../../../UI/HOC/ParagraphSet/ParagraphSet';

const About = (props) => {
   const { about } = props;
	return (
		<Section type="grey">
			<Content>
				<div className={css.Flex}>
					<InnerGridContainer className={css.Photo} valign="top">
						<div>
							<img
								src={about.image}
								alt="founders"
								style={{ width: '100%', height: 'auto' }}
							/>
						</div>
					</InnerGridContainer>
					<InnerGridContainer className={css.Content} valign="top">
						<ParagraphSet>
							{about.content}
						</ParagraphSet>
					</InnerGridContainer>
				</div>
			</Content>
		</Section>
	);
};
export default About;
