import {
	faArrowsAltV,
	faBolt,
	faCar,
	faCarBattery,
	faCheckDouble,
	faClipboardCheck,
	faCog,
	faDotCircle,
	faExclamationTriangle,
	faKey,
	faLeaf,
	faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import { faSnowflake } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import css from './ServiceIcons.module.css';

const ServicesIcons = (props) => {
	let icon = '';

	const { isSmall, type } = props;

	switch (type) {
		case 'Logbook Services':
			icon = faClipboardCheck;
			break;
		case 'Tyres & Balancing':
			icon = faDotCircle;
			break;
		case 'Tyres & Alignment':
			icon = faDotCircle;
			break;
		case 'Wheel Alignment':
			icon = faArrowsAltV;
			break;
		case 'Tyre Specialist':
			icon = faDotCircle;
			break;
		case 'Air Conditioning':
			icon = faSnowflake;
			break;
		case 'Auto Electrics':
			icon = faBolt;
			break;
		case 'Suspension':
			icon = faArrowsAltV;
			break;
		case 'Steering & suspension':
			icon = faArrowsAltV;
			break;
		case 'Key Drop-off box':
			icon = faKey;
			break;
		case 'Battery replacement':
			icon = faCarBattery;
			break;
		case 'General Mechanical Repairs':
			icon = faCar;
			break;
		case 'Engine Diagnostic':
			icon = faCog;
			break;
		case 'Brake & Clutch Services':
			icon = faExclamationTriangle;
			break;
		case 'Puncture Repair':
			icon = faExclamationTriangle;
			break;
		case 'Roadworthy Services':
			icon = faCheckDouble;
			break;
		case 'LPG':
			icon = faLeaf;
			break;
		default:
			icon = faThumbsUp;
	}

	const cssServiceIcons = isSmall ? css.ServicesIconsSmall : css.ServicesIcons;
	const cssIconContainer = isSmall
		? css.IconContainerSmall
		: css.IconContainer;
	const cssServiceTitle = isSmall ? css.ServicesTitleSmall : css.ServicesTitle;

	return (
		<div className={cssServiceIcons}>
			<div className={cssIconContainer}>
				<FontAwesomeIcon className={css.Icon} icon={icon} />
			</div>
			<div className={cssServiceTitle}>{props.type}</div>
			{!isSmall && (
				<div className={css.ServicesContent}>{props.description}</div>
			)}
		</div>
	);
};
export default ServicesIcons;
