// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Reviews_ReviewCardsContainer___7KQd {\n\tdisplay: inline-flex;\n\tflex-wrap: wrap;\n\tmargin: -30px 0 0 -30px;\n\twidth: calc(100% + 30px);\n}\n\n.Reviews_ReviewCardsContainer___7KQd > * {\n\tmargin: 30px 0 0 30px;\n\twidth: calc((100% / 3) - 30px);\n}\n\n@media (max-width: 799px) {\n\t.Reviews_ReviewCardsContainer___7KQd > * {\n\t\tmargin: 30px 0 0 30px;\n\t\twidth: 100%;\n\t}\n}\n", "",{"version":3,"sources":["webpack://src/components/Homepage/Reviews.page/Reviews.module.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;CACpB,eAAe;CACf,uBAAuB;CACvB,wBAAwB;AACzB;;AAEA;CACC,qBAAqB;CACrB,8BAA8B;AAC/B;;AAEA;CACC;EACC,qBAAqB;EACrB,WAAW;CACZ;AACD","sourcesContent":[".ReviewCardsContainer {\n\tdisplay: inline-flex;\n\tflex-wrap: wrap;\n\tmargin: -30px 0 0 -30px;\n\twidth: calc(100% + 30px);\n}\n\n.ReviewCardsContainer > * {\n\tmargin: 30px 0 0 30px;\n\twidth: calc((100% / 3) - 30px);\n}\n\n@media (max-width: 799px) {\n\t.ReviewCardsContainer > * {\n\t\tmargin: 30px 0 0 30px;\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReviewCardsContainer": "Reviews_ReviewCardsContainer___7KQd"
};
export default ___CSS_LOADER_EXPORT___;
