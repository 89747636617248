const data = {
	hostname: 'www.malverntyreandservice.com.au',
	id: 'malverntyreandservice',
	bookingLink: 'https://book.repairy.au/malverntyreservice',
	name: 'Malvern Tyre & Service',
	logo: '/images/MalvernTyreAndService/MalvernTyreAndServices-logo-2.svg',
	heroImage: '/images/MalvernTyreAndService/hero.jpg',
	theme: '#ed6c0b',
	intro: 'Your local independent garage for tyre and car servicing needs',
	services: [
		{
			id: 1,
			type: 'Tyre Specialist',
			content:
				'We offer a great range of tyres, including Michelin and Hankook and have the experience and equipment to make sure you get the right job done the first time.',
		},
		{
			id: 2,
			type: 'Steering & suspension',
			content:
				'Our technicians are equipped with the knowledge and hands-on experience in repairing, replacing and servicing the components of the steering and suspension systems on all vehicles.',
		},
		{
			id: 3,
			type: 'Logbook Services',
			content:
				'All our log book servicing is performed as per the factory schedules to ensure your warranty will be maintained regardless of the vehicle.',
		},
		{
			id: 4,
			type: 'Roadworthy Services',
			content:
				'We are fully licensed and authorised to provide a roadworthy certification service.',
		},
	],
	otherServices: [
		'Tyres & Balancing',
		'Air Conditioning',
		'Auto Electrics',
		'Suspension',
		'Battery replacement',
		'General Mechanical Repairs',
		'Brake & Clutch Services',
		'Engine Diagnostic',
		'Roadworthy Services',
		'LPG',
	],
	reasons: [
		{
			id: 1,
			image: '/images/MalvernTyreAndService/years-of-experience.jpg',
			title: 'Years of experience',
			desc:
				'Our team consist of skilled mechanics that take pride in delivering premium and comprehensive mechanical assistance using the latest technology.',
		},
		{
			id: 2,
			image: '/images/MalvernTyreAndService/trust.jpg',
			title: 'Service you can trust',
			desc:
				'We pride ourselves in delivering the highest quality of customer service and products to our valued customers.',
		},
		{
			id: 3,
			image: '/images/MalvernTyreAndService/above-beyond.jpg',
			title: 'Above and beyond',
			desc:
				"If you're not happy with the service we provide, please let us know and we will do our best to achieve 100% customer satisfaction.",
		},
	],
	about: {
		image: '/images/MalvernTyreAndService/profile.jpg',
		content: [
			<div key="sub-header">About Malvern Tyre & Service</div>,
			<div key="header">How Michael started his journey</div>,
			<div key="content">
				Michael founded Malvern Tyre & Service in 2012 however the name was
				started much earlier by his grandfather in the 1950's. The same
				level of passion and experience still carries on today albeit with
				newer products and technologies.
				<br />
				<br />
				Michael got his start in the automotive industry at the age of 8
				when his father and him assist restoration on an old triumph. A love
				of cars developed and a brief stint at university a shell automotive
				apprenticeship was completed.
				<br />
				<br />
				As a qualified roadworthy inspector Michael is able to identify any
				safety issues and keep your family motor running reliably.
				<br />
				<br />
				<span style={{ fontSize: '1.4rem', fontWeight: '500' }}>
					Michael
				</span>
				<br />
				<span style={{ fontSize: '0.9rem' }}>
					Owner - Malvern Tyre & Service.
				</span>
			</div>,
		],
	},
	reviews: [
		{
			link:
				'https://www.google.com/search?q=malverntyreandservice&rlz=1C5CHFA_enAU776AU776&oq=malverntyreandservice&aqs=chrome..69i57j69i60l2j69i61.243j0j7&sourceid=chrome&ie=UTF-8#lrd=0x6ad66b6e6f230a4b:0x9a8a63a332fef689,1,,,',
			score: '4.4',
			totalReview: '31',
			type: 'Google',
		},
		{
			link: 'https://www.facebook.com/malverntyreandservice/reviews',
			score: '4.2',
			totalReview: '5',
			type: 'Facebook',
		},
	],
	contacts: {
		googleMap:
			'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3149.0067438715078!2d145.05572195144683!3d-37.88352497964044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad66b6e6f230a4b%3A0x9a8a63a332fef689!2sMALVERN%20TYRE%20%26%20SERVICE!5e0!3m2!1sen!2sau!4v1606958491047!5m2!1sen!2sau',
		address: ['1105 Dandenong Rd,', 'Malvern East, VIC 3145.'],
		phone: '03 9571 2888',
		email: 'info@malverntyreservice.com.au',
		opening: ['Monday - Friday: 8am - 5pm', 'Saturday: 8am - 12.30pm'],
	},
	isExtraLogos: true,
	extraLogos: [
		'/images/Others/hankook-2-lines.png',
		'/images/MalvernTyreAndService/michelin-logo.png',
		'/images/Others/VACC.png',
	],
};

export default data;
