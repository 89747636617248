import React from 'react';
import Content from '../../../UI/HOC/Content/Content';
import css from './ExtrasLogo.module.css';

const ExtrasLogo = (props) => {
	const { extraLogos } = props;
	const ret =
		extraLogos.length > 0 ? (
			<Content className={css.ExtrasLogoContainer}>
				{extraLogos.map((logo) => {
					return (
						<img
							key={logo}
							className={css.ExtraLogo}
							src={logo}
							alt="extra"
						/>
					);
				})}
			</Content>
		) : (
			''
		);
	return ret;
};
export default ExtrasLogo;
