import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import Stars from '../../../../UI/Stars/Stars';
import css from './ReviewCard.module.css';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ReviewCard = (props) => {
	const { type, based, link, stars } = props;
	let icon = null;
	switch (type) {
		case 'Facebook' || 'facebook':
			icon = faFacebook;
			break;
		case 'Google' || 'google':
			icon = faGoogle;
			break;
		default:
			break;
	}

	return (
		<div
			onClick={() => {
				var win = window.open(link, '_blank');
				win.focus();
			}}
			className={css.ReviewCard}
		>
			<div className={css.BrandIconContainer}>
				<FontAwesomeIcon className={css.BrandIcon} icon={icon} />
			</div>
			<div className={css.Content}>
				<div className={css.StarsContent}>
					<Stars input={stars} /> <br />
					Based on {based} reviews <FontAwesomeIcon className={css.ArrowRight} icon={faChevronRight} />
				</div>
				<div className={css.StarsArrowRight}>
					
				</div>
			</div>
		</div>
	);
};
export default ReviewCard;
