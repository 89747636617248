import React from 'react';
import Content from '../../../UI/HOC/Content/Content';
import Section from '../../../UI/HOC/Section/Section';
import SectionTitle from '../../../UI/HOC/Section/SectionTitle/SectionTitle';
import ReviewCard from './ReviewCard/ReviewCard';
import css from './Reviews.module.css';

const ReviewsPage = (props) => {
	const { reviews } = props;

	return (
		<Section>
			<Content>
				<SectionTitle>
					<>Our reputation says it all.</>
					<>They said we have done a great job and we are proud of it.</>
				</SectionTitle>
				<div className={css.ReviewCardsContainer}>
					{/* <ReviewCard
						stars="5.0"
						type="Facebook"
						based="6"
						link="https://www.facebook.com/mark.md.auto/reviews/?ref=page_internal"
					/> */}
					{reviews.map((review) => {
						return (
							<ReviewCard
                        key={review.type}
								stars={review.score}
								type={review.type}
								based={review.totalReview}
								link={review.link}
							/>
						);
					})}
				</div>
			</Content>
		</Section>
	);
};
export default ReviewsPage;
