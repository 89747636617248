import React from 'react';
import css from './ParagraphSet.module.css';

const ParagraphSet = (props) => {
	const cssAlignTo =
		props.alignTo === 'right' ? css.RightAlign : css.LeftAlign;
	return (
		<div
			{...props}
			className={[css.ParagraphSet, cssAlignTo, props.className].join(' ')}
		>
			<div className={css.Small}>{props.children[0]}</div>
			<div className={css.Title}>{props.children[1]}</div>
			<div className={css.Content}>{props.children[2]}</div>
		</div>
	);
};
export default ParagraphSet;
