import React from 'react';
import css from './Home.module.css';
import HeroPage from './Hero.page/Hero.page';
import ServicesPage from './Services.page/Services.page';
import ReviewsPage from './Reviews.page/Reviews.page';
import Reason from './Reason.page/Reason.page';
import About from './About.page/About.page';
import ContactPage from './Contact.page/Contact.page';
import Header from '../Header/Header';
import WorkshopsData from '../../containers/WorkshopsData';
import { useParams } from 'react-router';
import ExtrasLogo from './ExtrasLogo.page/ExtrasLogo.page';
import { Helmet } from 'react-helmet';

const Home = (props) => {
	const { id } = useParams();
	const host = window.location.hostname;
	let workshop = null;
	let isFound = true;
	if (
		host !== 'localhost' &&
		host !== 'repairy-workshop-lp-staging.herokuapp.com'
	) {
		workshop = WorkshopsData.find((workshop) => workshop.hostname === host);
	} else {
		workshop = WorkshopsData.find((workshop) => workshop.id === id);
	}

	workshop === undefined
		? (isFound = false)
		: document.documentElement.style.setProperty(
				'--main-color',
				workshop.theme
		  );

	return (
		<div>
			{!isFound ? (
				<div>Cannot find what you are looking for</div>
			) : (
				<div>
					<Helmet>
						<title>{workshop.name}</title>
						<meta name="description" content={workshop.name} />
					</Helmet>
					<Header
						logo={workshop.logo}
						bookingLink={workshop.bookingLink}
					/>
					<div className={css.Home}>
						{/* <BannerPage /> */}
						<HeroPage
							name={workshop.name}
							intro={workshop.intro}
							heroImage={workshop.heroImage}
							bookingLink={workshop.bookingLink}
						/>
						{workshop.isExtraLogos && (
							<ExtrasLogo extraLogos={workshop.extraLogos} />
						)}

						<ServicesPage otherServices={workshop.otherServices} services={workshop.services} />
						<Reason reasons={workshop.reasons} />
						<ReviewsPage reviews={workshop.reviews} />
						<About about={workshop.about} />
						<ContactPage
							workshopName={workshop.name}
							extraLogos={workshop.extraLogos}
							contacts={workshop.contacts}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
export default Home;
