import React from 'react';
import css from './InnerGridContainer.module.css';

const InnerGridContainer = (props) => {
	const { valign } = props;

	const cssAlign =
		valign === 'top'
			? css.AlignTop
			: valign === 'middle'
			? css.AlignMiddle
			: '';

	return (
		<div
			{...props}
			className={[css.InnerGridContainer, cssAlign, props.className].join(
				' '
			)}
		></div>
	);
};
export default InnerGridContainer;
