import React from 'react';
import css from './SectionTitle.module.css';

const SectionTitle = (props) => (
	<div className={[css.SectionTitle, props.className].join(' ')}>
		<div className={css.Line}></div>
		<div>{props.children[0]}</div>
		<div className={[css.SectionTitleDesc]}>{props.children[1]}</div>
	</div>
);
export default SectionTitle;
